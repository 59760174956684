import React from 'react';
import Referenzen from '../components/referenzen';
import Layout from '../components/layout'
import { graphql } from "gatsby"

const Referenzen_ = ({ data }) =>
    <Layout title="Referenzen">
        <Referenzen data={data} />
    </Layout>

export const query = graphql`
{
    allProject(sort: {fields: [konstruktionReihenfolge], order: ASC}) {
      edges {
        node {
          id
          titelLagebeschreibung
          beschreibung
          konstruktionsVariante
          konstruktionReihenfolge
          sonderkonstruktion
          gelander
          beschichtung
          entwasserungebene
          konstruktionsVariante
          fussboden
          projektgrosse
          ort
          slug
          path
          projekt
          teaser {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  
`

export default Referenzen_